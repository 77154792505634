<template>
 <InfoWraper>
  <!--    <Message />-->
  <!--    <Notification/>-->
  <!--    <Settings />-->
  <!--    <Support />-->
  <!--    <div class="nav-author">-->
  <!--      <sdDropdown placement="bottomRight" :action="['click']">-->
  <!--        <template v-slot:overlay>-->
  <!--          <NavAuth>-->
  <!--            <router-link @click="() => onFlagChangeHandle('english')" to="#">-->
  <!--              <img-->
  <!--                :src="require('../../../static/img/flag/english.png')"-->
  <!--                alt=""-->
  <!--              />-->
  <!--              <span>English</span>-->
  <!--            </router-link>-->
  <!--            <router-link @click="() => onFlagChangeHandle('germany')" to="#">-->
  <!--              <img-->
  <!--                :src="require('../../../static/img/flag/germany.png')"-->
  <!--                alt=""-->
  <!--              />-->
  <!--              <span>Germany</span>-->
  <!--            </router-link>-->
  <!--            <router-link @click="() => onFlagChangeHandle('spain')" to="#">-->
  <!--              <img-->
  <!--                :src="require('../../../static/img/flag/spain.png')"-->
  <!--                alt=""-->
  <!--              />-->
  <!--              <span>Spain</span>-->
  <!--            </router-link>-->
  <!--            <router-link @click="() => onFlagChangeHandle('turky')" to="#">-->
  <!--              <img-->
  <!--                :src="require('../../../static/img/flag/turky.png')"-->
  <!--                alt=""-->
  <!--              />-->
  <!--              <span>Turky</span>-->
  <!--            </router-link>-->
  <!--          </NavAuth>-->
  <!--        </template>-->
  <!--        <a to="#" class="head-example">-->
  <!--          <img :src="require(`../../../static/img/flag/${flag}.png`)" alt="" />-->
  <!--        </a>-->
  <!--      </sdDropdown>-->
  <!--    </div>-->

  <div class="nav-author">
   <sdPopover action="click" placement="bottomRight">
    <template v-slot:content>
     <UserDropDwon>
      <div class="user-dropdwon">
       <figure class="user-dropdwon__info">
        <!--                <img-->
        <!--                    v-if="profile.imageFileURL !== null && profile.imageFileURL != ''"-->
        <!--                    :src="profile.imageFileURL"-->
        <!--                    alt=""-->
        <!--                    style="max-width: 100px"-->
        <!--                />-->
        <!--                <img-->
        <!--                    v-else-->
        <!--                    :src="require('../../../static/img/avatar/profileImage.png')"-->
        <!--                    alt=""-->
        <!--                    style="max-width: 100px"-->
        <!--                />-->
        <figcaption>
         <sdHeading as="h5">{{ profile.fullName }}</sdHeading>
         <p>{{ profile.roleName }}</p>
        </figcaption>
       </figure>
       <ul class="user-dropdwon__links">
        <li>
         <router-link :to="{ name: 'me' }">
          <sdFeatherIcons type="user"/>
          Profiel
         </router-link>
        </li>
       </ul>
       <a class="user-dropdwon__bottomAction" href="#" @click="SignOut">
        <LogoutOutlined/>
        Uitloggen
       </a>
      </div>
     </UserDropDwon>
    </template>
    <a class="head-example" to="#">
     <a-avatar
      v-if="profile.imageFileURL && profile.imageFileURL != ''"
      :src="profile.imageFileURL"
     />
     <a-avatar
      v-else
      :src="require('../../../static/img/avatar/user.svg')"
     />
    </a>
   </sdPopover>
  </div>
 </InfoWraper>
</template>

<script>
import {InfoWraper, UserDropDwon} from "./auth-info-style";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, defineComponent} from "vue";
import {LogoutOutlined} from "@ant-design/icons-vue";

export default defineComponent({
 name: "AuthInfo",
 components: {
  InfoWraper,
  UserDropDwon,
  LogoutOutlined,
 },
 data() {
  return {
   flag: "english",
  };
 },
 setup() {
  const {dispatch, state} = useStore();
  const router = useRouter();
  const profile = computed(() => state.profile.profile);
  const isLoading = computed(() => state.auth.loading);

  const logOutRoot = () => {
   router.push({name: 'login'});
  };

  const SignOut = (e) => {
   e.preventDefault();
   dispatch("logout", logOutRoot);
  };

  return {
   SignOut,
   isLoading,
   profile,
  };
 },
 methods: {
  onFlagChangeHandle: function (value) {
   this.flag = value;
  },
 },
});
</script>
