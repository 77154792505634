<template>
 <a-menu
  v-model:openKeys="openKeys"
  v-model:selectedKeys="selectedKeys"
  :mode="mode"
  :theme="darkMode ? 'dark' : 'light'"
 >
  <a-menu-item-group v-if="$ability.can('index', 'dashboard')" key="dashboardGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">Dashboards</p>
   </template>
   <a-menu-item v-if="$ability.can('index', 'dashboard')" key="dashboard" @click="toggleCollapsed">
    <router-link to="/dashboard">
     <sdFeatherIcons type="activity"/>
     <span>Verkoopprestaties</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>

  <a-menu-item-group v-if="$ability.can('read', 'order') || $ability.can('create', 'order')" key="orderGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">Orders</p>
   </template>

   <a-menu-item v-if="$ability.can('index', 'order')" key="order" @click="toggleCollapsed">
    <router-link to="/order">
     <sdFeatherIcons type="shopping-bag"/>
     <span>Order overzicht</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('create', 'order')" key="createOrder" @click="toggleCollapsed">
    <router-link to="/order/flow">
     <sdFeatherIcons type="shopping-cart"/>
     <span>Order aanmaken</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>

  <!--  <a-menu-item-group v-if="$ability.can('index', 'task')" key="taskGroup">-->
  <!--   <template v-slot:title>-->
  <!--    <p class="sidebar-nav-title">Taken</p>-->
  <!--   </template>-->
  <!--   <a-menu-item v-if="$ability.can('index', 'task')" key="task" @click="toggleCollapsed">-->
  <!--    <router-link to="/task/all">-->
  <!--     <sdFeatherIcons type="clipboard"/>-->
  <!--     <span>Overzicht</span>-->
  <!--    </router-link>-->
  <!--   </a-menu-item>-->
  <!--  </a-menu-item-group>-->

  <a-menu-item-group v-if="$ability.can('index', 'relation')" key="relationGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">CRM</p>
   </template>
   <a-menu-item key="relation" @click="toggleCollapsed">
    <router-link to="/relation">
     <sdFeatherIcons type="users"/>
     <span>Relaties</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>

  <a-menu-item-group v-if="$ability.can('index', 'relation')" key="relationGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">Leads</p>
   </template>
   <!--   <a-menu-item key="d2d" @click="toggleCollapsed">-->
   <!--    <router-link to="/d2d">-->
   <!--     <sdFeatherIcons type="map"/>-->
   <!--     <span>Door 2 Door</span>-->
   <!--    </router-link>-->
   <!--   </a-menu-item>-->
   <a-menu-item key="leads" @click="toggleCollapsed">
    <router-link to="/leads">
     <sdFeatherIcons type="phone-forwarded"/>
     <span>Leads</span>
    </router-link>
   </a-menu-item>
   <a-menu-item key="area" @click="toggleCollapsed">
    <router-link to="/area">
     <sdFeatherIcons type="map-pin"/>
     <span>Gebieden</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>

  <a-menu-item-group
   v-if="$ability.can('index', 'product')||$ability.can('read', 'supplier')||$ability.can('read', 'script')||$ability.can('read', 'flowquestion')"
   key="productGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">Producten</p>
   </template>
   <a-menu-item v-if="$ability.can('index', 'product')" key="product" @click="toggleCollapsed">
    <router-link to="/product">
     <sdFeatherIcons type="package"/>
     <span>Product overzicht</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'product')" key="question" @click="toggleCollapsed">
    <router-link to="/question">
     <sdFeatherIcons type="help-circle"/>
     <span>Product vragen</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'supplier')" key="supplier" @click="toggleCollapsed">
    <router-link to="/supplier">
     <sdFeatherIcons type="truck"/>
     <span>Leverancier</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'script')" key="script" @click="toggleCollapsed">
    <router-link to="/script">
     <sdFeatherIcons type="file-text"/>
     <span>Script</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'substatus')" key="substatus" @click="toggleCollapsed">
    <router-link to="/substatus">
     <sdFeatherIcons type="git-pull-request"/>
     <span>Substatussen</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>

  <a-menu-item-group v-if="$ability.can('index', 'export') || $ability.can('index', 'import')" key="dataGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">Data</p>
   </template>
   <a-menu-item v-if="$ability.can('index', 'export')" key="export" @click="toggleCollapsed">
    <router-link to="/export">
     <sdFeatherIcons type="download-cloud"/>
     <span>Export</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'import')" key="import" @click="toggleCollapsed">
    <router-link to="/import">
     <sdFeatherIcons type="upload-cloud"/>
     <span>Import</span>
    </router-link>
   </a-menu-item>
   <!--      <a-menu-item @click="toggleCollapsed" key="import">-->
   <!--        <router-link to="/app/ecommerce/orders">-->
   <!--          <sdFeatherIcons type="upload"/>-->
   <!--          <span>Import</span>-->
   <!--        </router-link>-->
   <!--      </a-menu-item>-->
  </a-menu-item-group>

  <a-menu-item-group
   v-if="$ability.can('index', 'organization')||$ability.can('index', 'sender')||$ability.can('index', 'team')||$ability.can('index', 'user')"
   key="configurationGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">Configuratie</p>
   </template>
   <a-menu-item v-if="$ability.can('index', 'organization')" key="organizationList" @click="toggleCollapsed">
    <router-link to="/organization">
     <sdFeatherIcons type="tag"/>
     <span>Organisatie</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'sender')" key="senderList" @click="toggleCollapsed">
    <router-link to="/sender">
     <sdFeatherIcons type="at-sign"/>
     <span>Afzender</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'team')" key="team" @click="toggleCollapsed">
    <router-link to="/team">
     <sdFeatherIcons type="users"/>
     <span>Team</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'user')" key="userList" @click="toggleCollapsed">
    <router-link to="/user">
     <sdFeatherIcons type="user-plus"/>
     <span>Gebruikers</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'role')" key="role" @click="toggleCollapsed">
    <router-link to="/role">
     <sdFeatherIcons type="key"/>
     <span>Rollen</span>
    </router-link>
   </a-menu-item>
   <a-menu-item v-if="$ability.can('index', 'scheduledtask')" key="scheduledtask" @click="toggleCollapsed">
    <router-link to="/scheduledtask">
     <sdFeatherIcons type="terminal"/>
     <span>Systeemtaken</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>
  <!--  <a-menu-item-group-->
  <!--   v-if="$ability.can('index', 'apps')"-->
  <!--   key="logGroup">-->
  <!--   <template v-slot:title>-->
  <!--    <p class="sidebar-nav-title">Apps</p>-->
  <!--   </template>-->
  <!--   <a-menu-item v-if="$ability.can('index', 'apps')" key="apps" @click="toggleCollapsed">-->
  <!--    <router-link to="/apps">-->
  <!--     <sdFeatherIcons type="codesandbox"/>-->
  <!--     <span>Apps</span>-->
  <!--    </router-link>-->
  <!--   </a-menu-item>-->
  <!--  </a-menu-item-group>-->
  <a-menu-item-group
   v-if="$ability.can('index', 'auditlog')"
   key="logGroup">
   <template v-slot:title>
    <p class="sidebar-nav-title">logs</p>
   </template>
   <a-menu-item v-if="$ability.can('index', 'auditlog')" key="auditlog" @click="toggleCollapsed">
    <router-link to="/log/audit">
     <sdFeatherIcons type="shield"/>
     <span>Audit log</span>
    </router-link>
   </a-menu-item>
  </a-menu-item-group>
 </a-menu>
</template>
<script>
import {computed, defineComponent, reactive, ref, toRefs, watch, watchEffect,} from "vue";
import VueTypes from "vue-types";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import versions from "../demoData/changelog.json";
import {ABILITY_TOKEN} from '@casl/vue';

export default defineComponent({
 name: "AsideItems",
 props: {
  toggleCollapsed: VueTypes.func,
  events: VueTypes.object,
 },
 setup(props) {
  const store = useStore();
  const darkMode = computed(() => store.state.themeLayout.data);
  const mode = ref("inline");
  const {events} = toRefs(props);
  const {
   onRtlChange,
   onLtrChange,
   modeChangeDark,
   modeChangeLight,
   modeChangeTopNav,
   modeChangeSideNav,
  } = events.value;

  const router = computed(() => useRoute());
  const state = reactive({
   selectedKeys: ["home"],
   openKeys: ["dashboard"],
   preOpenKeys: ["dashboard"],
  });

  watchEffect(() => {
   if (router.value.matched.length) {
    if (router.value.matched.length > 2) {
     state.selectedKeys = [router.value.matched[2].name];
     state.openKeys = [router.value.matched[1].name];
     state.preOpenKeys = [router.value.matched[1].name];
    } else if (router.value.matched.length > 3) {
     state.selectedKeys = [router.value.matched[3].name];
     state.openKeys = [router.value.matched[1].name];
     state.preOpenKeys = [router.value.matched[1].name];
    } else {
     state.selectedKeys = [router.value.matched[1].name];
     state.openKeys = [router.value.matched[1].name];
     state.preOpenKeys = [router.value.matched[1].name];
    }
   }
  });

  watch(
   () => state.openKeys,
   (val, oldVal) => {
    state.preOpenKeys = oldVal;
   }
  );

  return {
   inject: {
    $ability: {from: ABILITY_TOKEN}
   },
   mode,
   ...toRefs(state),
   darkMode,
   onRtlChange,
   onLtrChange,
   modeChangeDark,
   modeChangeLight,
   modeChangeTopNav,
   modeChangeSideNav,
   versions,
  };
 },
});
</script>
<style>
.ant-tooltip-inner i {
 padding-right: 5px;
 vertical-align: middle;
}

.ant-tooltip-inner span {
 vertical-align: middle;
}
</style>
